import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

export default function Guarantee({
  heading,
  icon,
  subheading,
}: {
  heading: string
  icon: IconDefinition
  subheading: string
}) {
  return (
    <div className='relative my-4 flex w-full flex-1 items-center p-8 md:max-w-2xl lg:max-w-4xl'>
      <div className='absolute left-0 top-0 size-full rounded-lg bg-primary300 p-8 mix-blend-multiply'>
        {/*-- this div contains mix-blend-mode-multiply without harming any other parts--*/}
      </div>
      <div
        className='relative mr-4 flex items-center justify-center'
        style={{ height: '60px', minWidth: '60px' }}
      >
        <div className='absolute size-full rounded-md bg-primary400 p-4 mix-blend-multiply'>
          {/*-- this div contains mix-blend-mode-multiply without harming any other parts--*/}
        </div>
        <Icon className='absolute text-white' icon={icon} size='lg' />
      </div>
      <div className='relative grid grid-cols-1'>
        <Text
          className='leading-[160%] text-white'
          styleName='p-small'
          value={heading}
        />
        <Text
          className='leading-[140%] text-primary100'
          styleName='p-tiny'
          value={subheading}
        />
      </div>
    </div>
  )
}
