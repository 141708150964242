import Link from '@components/primitives/Link'
import { type Url } from '@lib/routes'
import Button, { type ButtonProps } from './index'

export const LinkButton = (
  props: ButtonProps & {
    href: Url
    linkClassName?: string
    linkPrefetch?: boolean
    newTab?: boolean
    rel?: string
  },
) => {
  return (
    <Link
      className={props.linkClassName}
      href={props.href}
      newTab={props.newTab}
      prefetch={props.linkPrefetch}
      rel={props.rel}
      suppressHydrationWarning
    >
      <Button {...props} />
    </Link>
  )
}
