import Cookies from 'universal-cookie'

export default function signOutUser(): void {
  const cookies = new Cookies()

  cookies.remove('jwt', {
    path: '/',
    sameSite: 'strict',
    secure: true,
  })
}
