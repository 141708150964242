import type { TrackContext } from '@lib/tracking'
import type { TrialApplicationUtmParams } from '@modules/patientTrialApplications/helpers/tracking/getTrialApplicationUtmParamsFromCookie'

export const URL_PARAM_STORAGE_KEY = 'urlParams'

export default function extractTrackingOptionsFromUtmParams(
  urlParams: TrialApplicationUtmParams,
) {
  const trackingOptions = {
    context: {
      campaign: {
        content: urlParams.utm_content ?? '',
        medium: urlParams.utm_medium ?? '',
        name: urlParams.utm_campaign ?? '',
        referrer: urlParams.referrer ?? '',
        source: urlParams.utm_source ?? '',
        // We need to pass the three main utm params in, but the others are optional
        // https://segment.com/docs/connections/destinations/catalog/google-analytics/#utm-parameters
        term: urlParams.utm_term ?? '',
      },
    } as TrackContext,
  }
  return trackingOptions
}

export const emptySegmentTrackingContextCampaign: { context: TrackContext } = {
  context: {
    campaign: {
      content: '',
      medium: '',
      name: '',
      referrer: '',
      source: '',
      term: '',
    },
  },
}
