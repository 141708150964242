export const colorClasses = {
  baby: {
    accent: 'bg-baby100 text-baby900',
    filled: 'bg-baby500 text-black',
    outlined: 'bg-white text-baby800',
  },
  black: {
    accent: 'bg-neutral300 text-black',
    filled: 'bg-black text-white',
    outlined: 'bg-white text-black800',
  },
  blue: {
    accent: 'bg-blue100 text-blue900',
    filled: 'bg-blue500 text-white',
    outlined: 'bg-white text-blue800',
  },
  darkGrey: {
    accent: 'bg-neutral100 text-neutral900',
    filled: 'bg-neutral100 text-black',
    outlined: 'bg-white text-neutral800',
  },
  destructive: {
    accent: 'bg-destructive100 text-destructive900',
    filled: 'bg-destructive500 text-white',
    outlined: 'bg-white text-destructive800',
  },
  green: {
    accent: 'bg-green100 text-green900',
    filled: 'bg-green500 text-black',
    outlined: 'bg-white text-green800',
  },
  hope: {
    accent: 'bg-hope100 text-hope900',
    filled: 'bg-hope500 text-black',
    outlined: 'bg-white text-hope800',
  },
  lightGrey: {
    accent: 'bg-neutral100 text-neutral900',
    filled: 'bg-neutral100 text-black',
    outlined: 'bg-white text-neutral800',
  },
  mediumGrey: {
    accent: 'bg-neutral100 text-neutral900',
    filled: 'bg-neutral100 text-black',
    outlined: 'bg-white text-neutral800',
  },
  neutral: {
    accent: 'bg-neutral100 text-neutral900',
    filled: 'bg-neutral100 text-black',
    outlined: 'bg-white text-neutral800',
  },
  primary: {
    accent: 'bg-primary100 text-primary900',
    filled: 'bg-primary500 text-white',
    outlined: 'bg-white text-primary800',
  },
  seaGreen: {
    accent: 'bg-seaGreen100 text-seaGreen900',
    filled: 'bg-seaGreen500 text-black',
    outlined: 'bg-white text-seaGreen800',
  },
  success: {
    accent: 'bg-success100 text-success900',
    filled: 'bg-success600 text-white',
    outlined: 'bg-white text-success800',
  },
  transparent: {
    accent: 'bg-transparent text-white-25',
    filled: 'bg-transparent text-white-25',
    outlined: 'bg-transparent text-white-25',
  },
  turquoise: {
    accent: 'bg-turquoise100 text-turquoise900',
    filled: 'bg-turquoise500 text-black',
    outlined: 'bg-white text-turquoise800',
  },
  warm: {
    accent: 'bg-warm100 text-warm900',
    filled: 'bg-warm500 text-black',
    outlined: 'bg-white text-warm800',
  },
  warning: {
    accent: 'bg-warning100 text-warning900',
    filled: 'bg-warning500 text-white',
    outlined: 'bg-white text-warning800',
  },
  white: {
    accent: 'bg-white text-black',
    filled: 'bg-white text-black',
    outlined: 'bg-white text-black',
  },
} as const
