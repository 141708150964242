import get from 'lodash/get'
import isNil from 'lodash/isNil'

export type LocalStorageKey =
  | 'geolocation'
  | 'postTrialApply'
  | 'test'
  | 'trialApplication'
  | 'trialProLastViewedNoteIdTokens'
  | 'trialProMatchesActiveClinicalLocationFilters'
  | 'trialProMatchesActiveSiteFilters'
  | 'trialProMatchesActiveTrialFilters'
  | 'trialProMatchesActiveOtherFilters'
  | 'trialProMatchesActiveSortFilter'
  | 'trialProOnboarding'
  | 'trialSearchHistory'
  | 'userSearchToken'

export default class LocalStorageItem {
  private key: LocalStorageKey

  constructor(key: LocalStorageKey) {
    this.key = key
  }

  // NOTE: native JSON implementation does not support serialization of dates, bigint, etc.
  // Consider refactoring this to leverage `superjson` if the use case arises
  public getItem = <T = unknown>(key?: string | string[]): T | void => {
    // `isProcessClientside` uses process.browser, which is deprecated
    // Use an undefined window to denote the localStorage API is unavailable
    if (typeof window === 'undefined') {
      return
    }

    const item = localStorage.getItem(this.key)

    // A bug in a consumer of this class was passing in `undefined` which was then
    // stringified and saved. This prevents an exception from being thrown in that case.
    if (isNil(item) || item === 'undefined') {
      return
    }

    const parsedData = JSON.parse(item)
    return key ? get(parsedData, key) : parsedData
  }

  public removeItem = (key?: string) => {
    localStorage.removeItem(key ?? this.key)
  }

  public setItem = <T = unknown>(value: T) => {
    if (isNil(value)) {
      return
    }

    localStorage.setItem(this.key, JSON.stringify(value))
  }
}
