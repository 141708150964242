import { isProcessClientSide } from '@lib/config/runtime'
import isbot from 'isbot'

/**
 * Analytics plugin to avoid sending analytics when the client is a bot.
 * Based on the Do Not Track plugin:
 *   https://github.com/DavidWells/analytics/tree/master/packages/analytics-plugin-do-not-track
 * @param _userConfig Empty configuration object
 */
export default function isNotBot(_userConfig = {}) {
  return {
    config: {},

    identifyStart: ({ abort }: { abort: (reason: string) => void }) => {
      if (detectBot()) {
        return abort('Cancel the identify call because bot detected')
      }
    },

    // Shape of the abort from https://github.com/DavidWells/analytics/blob/master/packages/analytics-core/src/middleware/plugins/engine.js#L209
    initializeStart: ({ abort }: { abort: (reason: string) => void }) => {
      if (detectBot()) {
        return abort('Cancel the initialize call because bot detected')
      }
    },
    name: 'is-not-bot',
    pageStart: ({ abort }: { abort: (reason: string) => void }) => {
      if (detectBot()) {
        return abort('Cancel the page call because bot detected')
      }
    },
    trackStart: ({ abort }: { abort: (reason: string) => void }) => {
      if (detectBot()) {
        return abort('Cancel the track call because bot detected')
      }
    },
  }
}

/**
 * Given a user agent string, return boolean on whether or not it is a bot. Blank strings
 * are considered not bots.
 * @param userAgentString Blank or navigator.userAgent
 */
export function detectBot(userAgentString = getUserAgentString()): boolean {
  return isbot(userAgentString)
}

/**
 * Makes best guess at current user agent, returning blank if indeterminate.
 */
function getUserAgentString() {
  if (!isProcessClientSide()) {
    return ''
  }

  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent === 'undefined'
  ) {
    return ''
  }

  return navigator.userAgent
}
