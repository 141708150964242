import { isProcessClientSide } from '@lib/config/runtime'
import isProductionEnv from '@lib/utilities/isProductionEnv'
import type { UrlObject } from 'url'
import { WWW_WITHPOWER_COM } from '..'

const LOCALHOST_URL = 'localhost:3000'

// remove 'https://' from FC_URL
const DEPLOYMENT_URL = process.env.FC_URL
  ? new URL(process.env.FC_URL).hostname
  : undefined

function isHttpsEnv() {
  return DEPLOYMENT_URL ?? process.env.NODE_ENV === 'production'
}

function isCurrentEnvProduction() {
  return (
    process.env.CURRENT_ENV === 'production' ||
    process.env.NEXT_PUBLIC_CURRENT_ENV === 'production'
  )
}

/**
 * Fully qualified domain name or hostname for the app, www.withpower.com for `production`,
 * use DEPLOYMENT_URL for `preview` or if set, and localhost for `development` or everything else
 */
export const FQDN = fqdn()

function fqdn() {
  if (isProcessClientSide()) {
    return window.location.hostname === 'localhost'
      ? LOCALHOST_URL // add port for dev clientside links
      : window.location.hostname
  }

  if (isProductionEnv()) {
    return WWW_WITHPOWER_COM
  }

  return DEPLOYMENT_URL ?? LOCALHOST_URL
}

function isAbsoluteUrl(maybeUrl: string) {
  try {
    const url = new URL(maybeUrl)

    return Boolean(url.host)
  } catch (e: unknown) {
    return false
  }
}

/**
 * Returns a fully qualified domain name + path string, e.g.: https://www.withpower.com/clinical-trials/prostate-cancer
 */
export default function absoluteUrl(
  path: string | UrlObject,
  { forceProduction = false }: { forceProduction: boolean } = {
    forceProduction: false,
  },
) {
  if (isAbsoluteUrl(path.toString())) {
    return path.toString()
  }

  // Allow local builds to point to production avoid hydration issues by forcing production if our CURRENT_ENV is production
  if (!forceProduction && isCurrentEnvProduction()) {
    forceProduction = true
  }
  const domain = forceProduction ? WWW_WITHPOWER_COM : FQDN
  const protocol = isHttpsEnv() || forceProduction ? 'https' : 'http'
  const pathString = path.toString()
  const formattedPath = pathString.replace(/^\/+/, '')
  return `${protocol}://${domain}/${formattedPath}`
}
