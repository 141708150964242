import { Footer } from '@components/app/Footer'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'
/* These styles get imported globally */
import AuthenticationProvider from '@components/hooks/AuthenticationProvider'
import { FontWrapper } from '@components/primitives/Font'
import type { PageWidth } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { LayoutWithCookiesAndGeolocation } from '@components/primitives/Layout/LayoutWithCookiesAndGeolocation'
import '@fortawesome/fontawesome-svg-core/styles.css'
import type { ColorGroup } from '@lib/colors/colors'
import '@styles/global.scss'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & {
    contentWidth: PageWidth
    footerClassName?: string
    footerColorGroup?: ColorGroup
    footerHideGuaranteeBar?: boolean
    includeFooter?: boolean
  }
}

export default function WithPower({
  Component,
  pageProps,
}: AppPropsWithLayout) {
  const defaultLayout = function (page: ReactElement) {
    return (
      <LayoutWithCookiesAndGeolocation>{page}</LayoutWithCookiesAndGeolocation>
    )
  }

  // Use the layout defined at the page level, if available
  // Need to add Providers? Do so in the `Layout` component
  const getLayout = Component.getLayout ?? defaultLayout
  const includeFooter = Component.includeFooter ?? true

  return getLayout(
    <FontWrapper>
      <>
        <AuthenticationProvider>
          <Component {...pageProps} />
        </AuthenticationProvider>
        {includeFooter ? (
          <Footer
            className={Component.footerClassName}
            colorGroup={Component.footerColorGroup}
            hideGuaranteeBar={Component.footerHideGuaranteeBar}
            maxWidth={Component.contentWidth}
          />
        ) : null}
      </>
    </FontWrapper>,
  )
}
