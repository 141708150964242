import generateGA4AnalyticsScripts from '@lib/tracking/generateGA4AnalyticsScripts'
import generateHotJarSnippet from '@lib/tracking/generateHotJarSnippet'
import generateMazeSnippet from '@lib/tracking/generateMazeSnippet'
import generateRedditPixelSnippet from '@lib/tracking/generateRedditPixelSnippet'
import Head from 'next/head'
import Script from 'next/script'
import type { MetricsAndTrackingProps } from './types'

// These are public anyways
const HOTJAR_HJSV = '6'
const HOTJAR_HJID = '2549355'

const ga4Scripts = generateGA4AnalyticsScripts()
const hotjarSnippet = generateHotJarSnippet(HOTJAR_HJID, HOTJAR_HJSV)
const mazeSnippet = generateMazeSnippet()
const redditPixelSnippet = generateRedditPixelSnippet()

export default function MetricsAndTrackingScripts({
  loadHotJar,
  loadMaze = false,
  loadRedditPixel = true,
}: MetricsAndTrackingProps) {
  return (
    <>
      {/* Inject HotJar snippet into <head> if present (blank is falsey) */}
      {loadHotJar && hotjarSnippet ? (
        <>
          <Head>
            <link href='https://static.hotjar.com' rel='dns-prefetch' />
          </Head>
          <Script
            dangerouslySetInnerHTML={{
              __html: hotjarSnippet,
            }}
            id='hotjar_snippet'
            strategy='afterInteractive'
          />
        </>
      ) : null}
      {/* Maze */}
      {loadMaze && mazeSnippet ? (
        <>
          <Head>
            <link href='https://snippet.maze.co' rel='dns-prefetch' />
          </Head>
          <Script
            dangerouslySetInnerHTML={{
              __html: mazeSnippet,
            }}
            id='maze_snippet'
            strategy='afterInteractive'
          />
        </>
      ) : null}
      {/* Reddit Pixel */}
      {loadRedditPixel ? (
        <>
          <Script
            dangerouslySetInnerHTML={{
              __html: redditPixelSnippet,
            }}
            id='reddit_pixel'
            strategy='afterInteractive'
          />
        </>
      ) : null}
      {ga4Scripts}
    </>
  )
}
