import decodeJwt from '@modules/auth/jwt/decodeJwt'
import getJwtCookie from '@modules/auth/jwt/getJwtCookie'

export default function getUserIdTokenFromJwtCookie() {
  const jwtCookieData = getJwtCookie()
  if (jwtCookieData) {
    const decodedJwt = decodeJwt(jwtCookieData)
    if (decodedJwt.userIdToken) {
      return decodedJwt.userIdToken
    }
  }

  return
}
