import { useEffect, useState } from 'react'

/**
 * @param timeoutInMs - The timeout in milliseconds until the inactive state toggles to true.
 * @returns A tuple containing the inactive state and a function to reset the timer.
 * Custom hook which sets an inactive state once a specified duration has passed with no user inputs (mouse or keyboard).
 */
export default function useIsInactive(timeoutInMs: number) {
  const [lastActive, setLastActive] = useState(new Date())
  const [isInactive, setIsInactive] = useState(false)

  function handleUserActivity() {
    setLastActive(new Date())
  }

  function resetTimer() {
    setIsInactive(false)
    setLastActive(new Date())
  }

  useEffect(() => {
    // Desktop inputs
    document.addEventListener('keypress', handleUserActivity)
    document.addEventListener('mousemove', handleUserActivity)

    // Mobile inputs
    document.addEventListener('touchstart', handleUserActivity)
    document.addEventListener('touchend', handleUserActivity)
    document.addEventListener('touchcancel', handleUserActivity)
    document.addEventListener('touchmove', handleUserActivity)

    return () => {
      document.removeEventListener('keypress', handleUserActivity)
      document.removeEventListener('mousemove', handleUserActivity)

      document.removeEventListener('touchstart', handleUserActivity)
      document.removeEventListener('touchend', handleUserActivity)
      document.removeEventListener('touchcancel', handleUserActivity)
      document.removeEventListener('touchmove', handleUserActivity)
    }
  }, [])

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      const currentTime = new Date()
      const inactiveTimeInMs = currentTime.getTime() - lastActive.getTime()

      if (inactiveTimeInMs >= timeoutInMs) {
        setIsInactive(true)
      }
    }, timeoutInMs)

    return () => clearInterval(intervalTimer)
  }, [lastActive])

  return [isInactive, resetTimer] as const
}
