import cloneDeep from 'lodash/cloneDeep'
import Cookies from 'universal-cookie'
import type { InternalJsonFetchRequest } from '.'

export default function attachBearerToken(req: InternalJsonFetchRequest) {
  const reqWithBearerToken = cloneDeep(req)
  const cookies = new Cookies()
  const jwt = cookies.get('jwt')

  if (!jwt) {
    return reqWithBearerToken
  }

  reqWithBearerToken.headers = {
    ...reqWithBearerToken.headers,
    Authorization: `Bearer ${jwt}`,
  }

  return reqWithBearerToken
}
