import type { StringDictionary } from '@lib/types/dictionary'

export type QueryParameters = StringDictionary | string | URLSearchParams

export default function urlWithQueryParams(
  url: string,
  params?: QueryParameters,
): string {
  const paramString = new URLSearchParams(params).toString()
  return Boolean(paramString)
    ? `${url}${getQueryParamConnector(url)}${paramString}`
    : url
}

function getQueryParamConnector(url: string): string {
  return url.includes('?') ? '&' : '?'
}
