import type { ColorHexCode } from '@lib/colors/colors'
import { colors } from '@lib/colors/colors'
import type { LogoSize } from '..'

export default function PowerLogoIcon({
  fillColor = colors.black,
  opacity = 1,
  size = 'Medium',
}: {
  fillColor?: ColorHexCode
  opacity?: number
  size?: LogoSize
}) {
  function getSizeFromLogoSize(size: LogoSize) {
    switch (size) {
      case 'Giant':
        return 40
      case 'Large':
        return 24
      case 'Small':
        return 16
      default:
        return 20
    }
  }

  return (
    <svg
      fill='none'
      height={getSizeFromLogoSize(size)}
      viewBox={`0 0 28 28`}
      width={getSizeFromLogoSize(size)}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.2072 4.63447C20.9327 2.44955 18.6167 0.544922 15 0.544922H0V1.04492V5.94492V6.44492C1.16235 6.44492 1.87743 6.66167 2.3302 6.90238C2.7678 7.13503 3.11213 7.46991 3.41489 7.96534C4.11808 9.11601 4.43682 10.7893 4.83674 13.1888L4.87583 13.424C5.21988 15.4969 5.6682 18.198 6.97739 20.3403C7.70588 21.5324 8.72093 22.6038 10.1349 23.3555C11.5337 24.0992 13.1623 24.4449 15 24.4449V19.0449C13.8377 19.0449 13.1226 18.8282 12.6698 18.5875C12.2322 18.3548 11.8879 18.0199 11.5851 17.5245C10.8819 16.3738 10.5632 14.7006 10.1633 12.301L10.1242 12.0658L10.1242 12.0658C9.82151 10.2423 9.43816 7.93266 8.45907 5.94492H15C16.3833 5.94492 17.0673 6.5403 17.5428 7.35537C18.1093 8.32657 18.3 9.5681 18.3 10.2449C18.3 10.8911 18.1222 11.7906 17.6631 12.4466C17.3131 12.9465 16.654 13.5449 15 13.5449V18.9449C18.346 18.9449 20.6869 17.5433 22.0869 15.5433C23.3778 13.6992 23.7 11.5987 23.7 10.2449C23.7 8.92175 23.3907 6.66327 22.2072 4.63447Z'
        fill={fillColor}
        opacity={opacity}
      />
    </svg>
  )
}
