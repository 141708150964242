import getJwtCookie from '@modules/auth/jwt/getJwtCookie'
import { useEffect, useState } from 'react'

export default function useIsSignedIn() {
  const jwt = getJwtCookie()

  return useUserHasJwt(jwt)
}

export function useUserHasJwt(jwt: unknown) {
  const [isSignedIn, setIsSignedIn] = useState(false)

  useEffect(() => {
    setIsSignedIn(Boolean(jwt))
  }, [jwt])

  return isSignedIn
}
