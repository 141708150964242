import type { LocalStorageKey } from '@lib/browsers/localStorage/LocalStorageItem'
import LocalStorageItem from '@lib/browsers/localStorage/LocalStorageItem'
import type { ExtendedFormDataPoint, FormData } from '@lib/types/FormData'

/**
 * Generic form data storage layer
 */
export default class FormDataStorage {
  private form: LocalStorageItem

  constructor(key: LocalStorageKey) {
    this.form = new LocalStorageItem(key)
  }

  /**
   * Get a single item from the dictionary.
   *
   * @param key Name of the item to retrieve
   */
  public get = (key?: keyof FormData | [keyof FormData, ...string[]]) => {
    if (!key) {
      return this.getAll()
    }
    return this.form.getItem(key) as FormData
  }

  /**
   * Retrieve all items as a dictionary.
   */
  public getAll = () => {
    return (this.form.getItem() ?? {}) as Record<string, FormData>
  }

  /**
   * Clears the form data for this local storage.
   */
  public reset = () => {
    return this.form.removeItem()
  }

  /**
   * Set a single item to the dictionary
   * @param key Name of the item to store
   * @param value Value of the item to store
   */
  public set = (
    key: keyof FormData | string,
    value:
      | ExtendedFormDataPoint
      | Partial<Record<keyof FormData, ExtendedFormDataPoint>>,
  ) => {
    return this.form.setItem({
      ...this.getAll(),
      [key]: value,
    })
  }
}
