'use client'

import { usePrevious } from '@components/hooks/usePrevious'
import { URL_PARAM_STORAGE_KEY } from '@lib/browsers/cookies/extractTrackingOptionsFromUtmParams'
import { type UTMParameters } from '@lib/browsers/utm/types'
import { isProcessClientSide } from '@lib/config/runtime'
import { trackPageview } from '@lib/tracking'
import { convertToSegmentUTMs } from '@lib/tracking/convertToSegmentCampaign'
import { removeUndefinedKeys } from '@lib/utilities/object/removeUndefinedKeys'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { getQueryParamsToPersistInCookie } from './getQueryParamsToPersistInCookie.client'

export function UtmCookiesAndNavigationEvents() {
  const [cookies, setCookie] = useCookies([URL_PARAM_STORAGE_KEY])
  const pathname = usePathname()
  const searchParamsFromNext = useSearchParams()
  const reliableSearchParams = isProcessClientSide() // On first render, the useSearchParams from next is not reliably populated with the url params
    ? new URLSearchParams(window.location.search)
    : searchParamsFromNext

  const prevRoute = usePrevious(pathname)
  const doc = isProcessClientSide() ? document : null

  useEffect(() => {
    const urlParamCookie: UTMParameters = getQueryParamsToPersistInCookie(
      cookies as { urlParams: UTMParameters & { referrer?: string } },
      reliableSearchParams,
    )

    setCookie(URL_PARAM_STORAGE_KEY, urlParamCookie, {
      path: '/', // Allow all pages to read the cookie
      sameSite: 'lax',
      secure: true,
    })

    // Do not include setCookie or else we'll get infinite re-renders
    if (prevRoute !== pathname && pathname !== null) {
      const utmParams = removeUndefinedKeys(
        convertToSegmentUTMs(urlParamCookie),
      )
      trackPageview(pathname, reliableSearchParams, utmParams)
    }
  }, [searchParamsFromNext, doc, pathname])

  return null
}
