import { default as NextLink } from 'next/link'
import type { MouseEventHandler, ReactNode } from 'react'
import type { UrlObject } from 'url'

export type LinkProps = {
  children?: JSX.Element | JSX.Element[] | ReactNode
  className?: string
  href: UrlObject | string
  newTab?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  prefetch?: boolean
  rel?: string
  suppressHydrationWarning?: boolean
}

/**
 * A wrapper around the next/link component that provides convenience props
 */
export default function Link({
  children,
  className,
  href,
  newTab = false,
  onClick,
  prefetch = false,
  rel,
  suppressHydrationWarning = false,
}: LinkProps) {
  const relationships = newTab ? ['noreferrer noopener'] : []
  if (rel) {
    relationships.push(rel)
  }
  const relationship =
    relationships.length > 0 ? relationships.join(' ').trim() : undefined

  // NextJS warns when we use `prefetch={true}` prop, so only include if false
  const prefetchProp = !prefetch ? { prefetch: false } : {}

  return (
    <NextLink
      href={href}
      passHref
      {...prefetchProp}
      className={className}
      onClick={onClick}
      rel={relationship}
      suppressHydrationWarning={suppressHydrationWarning}
      target={newTab ? '_blank' : undefined}
    >
      {children}
    </NextLink>
  )
}
