import { fontFamily } from 'tailwindcss/defaultTheme'
import zIndices from './components/utilities/zIndex.js'
import colors from './lib/colors/colorConsts.js'

const tailwindConfig = {
  content: [
    './app/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './lib/**/*.{js,ts,jsx,tsx}',
  ],
  plugins: [],
  theme: {
    colors, // https://tailwindcss.com/docs/customizing-colors
    extend: {
      backgroundImage: ({ theme }) => {
        const steps = [
          'blue500',
          'blue300',
          'primary500',
          'blue500',
          'blue400',
          'blue100',
        ]
        const gradient = steps
          .map((color) => `${theme(`colors.${color}`)}`)
          .join(', ')
        return {
          checkMarkBlack:
            'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)',
          checkMarkWhite:
            'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)',
          ctaGradient: `linear-gradient(135deg,${gradient})`,
        }
      },
      boxShadow: {
        brand: '0px 0px 24px #d1d5db',
        focus: '0 0 2pt 1pt #c9b5ff',
      },
      fontFamily: {
        Manrope: ['var(--font-manrope)', ...fontFamily.sans],
      },
      gridTemplateColumns: {
        // Custom grid size used for SSP matches table
        52: 'repeat(52, minmax(0, 1fr))',
      },
      height: {
        ['144']: '36rem', // 576px
      },
      // animation: {
      //   textSlide: "textSlide 1s linear",
      // },
      keyframes: {
        horizontalRollLeft: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        movingGradient: {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' },
        },
        shake: {
          '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
          },
          '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
          },
          '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
          },
          '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
          },
        },
        textSlide: {
          '0%': {
            opacity: 0,
            transform: 'translateY(+300%)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
          'typing': {
            '0%': { width: '0' },
            '80%': { width: '10ch' },
            '100%': { width: '10ch' },
          },
          'verticalRollUp': {
            '0%': { transform: 'translateY(0%)' },
            '100%': { transform: 'translateY(-100%)' },
          },
        },
      },
      screens: {
        ssp: { raw: '(min-width: 960px)' },
      },
      transitionProperty: {
        height: 'height',
        lineclamp: 'overflow display -webkit-line-clamp',
      },
      width: {
        '1/10': '10%',
        '3/10': '30%',
        '7/10': '70%',
        '9/10': '90%',
        '112': '28rem', // 448px
      },
      zIndex: zIndices,
    },
  },
}

export default tailwindConfig
