export const spacing = {
  gigantic: 'gigantic' as const,
  huge: 'huge' as const,
  large: 'large' as const,
  medium: 'medium' as const,
  none: 'none' as const,
  small: 'small' as const,
  tiny: 'tiny' as const,
  xlarge: 'xlarge' as const,
  xsmall: 'xsmall' as const,
}

/* eslint-disable sort-keys */
export const sizeToSpacing = {
  none: 'gap-0',
  tiny: 'gap-0.5',
  xsmall: 'gap-1',
  small: 'gap-2',
  medium: 'gap-4',
  large: 'gap-6',
  xlarge: 'gap-8',
  huge: 'gap-12',
  gigantic: 'gap-96',
}
/* eslint-enable sort-keys */

export type Spacing = (typeof spacing)[keyof typeof spacing]
