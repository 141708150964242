import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { TextColor } from '@lib/colors/colors'
import type { Url } from '@lib/routes'
import DynamicRoutes from '@lib/routes/DynamicRoutes'

export type DirectoryLink = {
  label: string
  url: Url
}

export const topTenOtherConditionDirectories: DirectoryLink[] = [
  { label: 'Tinnitus', url: DynamicRoutes.topic('tinnitus') },
  {
    label: 'Retinitis Pigmentosa',
    url: DynamicRoutes.topic('retinitis-pigmentosa'),
  },
  {
    label: 'Spinal Cord Injury',
    url: DynamicRoutes.topic('spinal-cord-injury'),
  },
  { label: 'Essential Tremor', url: DynamicRoutes.topic('essential-tremor') },
  { label: 'Obesity', url: DynamicRoutes.topic('obesity') },
  { label: 'Vitiligo', url: DynamicRoutes.topic('vitiligo') },
  { label: 'Lyme Disease', url: DynamicRoutes.topic('lyme-disease') },
  { label: 'Lung Cancer', url: DynamicRoutes.topic('lung-cancer') },
  { label: 'Breast Cancer', url: DynamicRoutes.topic('breast-cancer') },
  { label: 'Pancreatic Cancer', url: DynamicRoutes.topic('pancreatic-cancer') },
]

export const topTenPsychologyConditionDirectories: DirectoryLink[] = [
  { label: 'Depression', url: DynamicRoutes.topic('depression') },
  { label: 'Schizophrenia', url: DynamicRoutes.topic('schizophrenia') },
  { label: 'Anxiety', url: DynamicRoutes.topic('anxiety') },
  { label: 'PTSD', url: DynamicRoutes.topic('ptsd') },
  { label: 'ADHD', url: DynamicRoutes.topic('adhd') },
  { label: 'Autism', url: DynamicRoutes.topic('autism') },
  { label: 'Bipolar Disorder', url: DynamicRoutes.topic('bipolar-disorder') },
  { label: 'Addiction', url: DynamicRoutes.topic('addiction') },
  { label: 'OCD', url: DynamicRoutes.topic('ocd') },
  {
    label: 'Eating Disorder',
    url: DynamicRoutes.topic('eating-disorder'),
  },
]

export const topTenTreatmentDirectories: DirectoryLink[] = [
  { label: 'Psilocybin', url: DynamicRoutes.topic('psilocybin') },
  { label: 'IVF', url: DynamicRoutes.topic('ivf') },
  { label: 'Dental Implant', url: DynamicRoutes.topic('dental-implant') },
  { label: 'Weight Loss', url: DynamicRoutes.topic('weight-loss') },
  { label: 'Smoking', url: DynamicRoutes.topic('smoking') },
  { label: 'Platelet-Rich Plasma', url: DynamicRoutes.topic('prp') },
  { label: 'Testosterone', url: DynamicRoutes.topic('testosterone') },
  { label: 'Saxenda', url: DynamicRoutes.topic('saxenda') },
  { label: 'Melatonin', url: DynamicRoutes.topic('melatonin') },
  { label: 'Entresto', url: DynamicRoutes.topic('entresto') },
]

export const topTrialByCity: DirectoryLink[] = [
  {
    label: 'Depression Studies',
    url: DynamicRoutes.mapSearchWedge(
      'depression-clinical-trials-by-state',
      'depression',
    ),
  },
  {
    label: 'Diabetes Studies',
    url: DynamicRoutes.mapSearchWedge(
      'diabetes-clinical-trials-by-state',
      'diabetes',
    ),
  },
  {
    label: 'Eczema Studies',
    url: DynamicRoutes.mapSearchWedge(
      'eczema-clinical-trials-by-state',
      'eczema',
    ),
  },
  {
    label: 'Psoriasis Studies',
    url: DynamicRoutes.mapSearchWedge(
      'psoriasis-clinical-trials-by-state',
      'psoriasis',
    ),
  },
  {
    label: 'Arthritis Studies',
    url: DynamicRoutes.mapSearchWedge(
      'arthritis-clinical-trials-by-state',
      'arthritis',
    ),
  },
  {
    label: 'Schizophrenia Studies',
    url: DynamicRoutes.mapSearchWedge(
      'schizophrenia-clinical-trials-by-state',
      'schizophrenia',
    ),
  },
  {
    label: 'Osteoarthritis Studies',
    url: DynamicRoutes.mapSearchWedge(
      'osteoarthritis-clinical-trials-by-state',
      'osteoarthritis',
    ),
  },
]

export const topTenStateDirectories: DirectoryLink[] = [
  { label: 'Florida', url: DynamicRoutes.topic('florida') },
  { label: 'New Jersey', url: DynamicRoutes.topic('new-jersey') },
  { label: 'North Carolina', url: DynamicRoutes.topic('north-carolina') },
  { label: 'Texas', url: DynamicRoutes.topic('texas') },
  { label: 'Ohio', url: DynamicRoutes.topic('ohio') },
  { label: 'California', url: DynamicRoutes.topic('california') },
  { label: 'Pennsylvania', url: DynamicRoutes.topic('pennsylvania') },
  { label: 'Kentucky', url: DynamicRoutes.topic('kentucky') },
  { label: 'New York', url: DynamicRoutes.topic('new-york') },
  { label: 'Indiana', url: DynamicRoutes.topic('indiana') },
]

type DirectoryLinksProps = {
  className?: string
  directories: DirectoryLink[]
  itemClassName?: string
  onClickLink?: () => void
  textColor?: TextColor
  useStack?: boolean
}

export const DirectoryLinks = ({
  className,
  directories,
  itemClassName,
  onClickLink,
  textColor = 'text-black',
  useStack = false,
}: DirectoryLinksProps) => {
  const links = directories.map((directory) => (
    <div key={directory.url.toString()} onClick={onClickLink}>
      <Link className={className} href={directory.url}>
        <Text
          className={classMerge(itemClassName, textColor, 'text-left')}
          styleName='p'
          value={directory.label}
        />
      </Link>
    </div>
  ))
  return useStack ? (
    <div className='grid grid-cols-1 gap-2'>{links}</div>
  ) : (
    <>{links}</>
  )
}

DirectoryLinks.Stack = Object.assign(
  (directoryLinksProps: DirectoryLinksProps) => (
    <DirectoryLinks {...directoryLinksProps} useStack={true} />
  ),
  { displayName: 'DirectoryLinks.Stack' },
)
