import decodeJwt from '@modules/auth/jwt/decodeJwt'
import getJwtCookie from '@modules/auth/jwt/getJwtCookie'
import type { UserJwtPayload } from '@modules/auth/jwt/types'
import { getUserSegmentAnonymousId } from '.'

export default function generateRedditPixelSnippet() {
  const jwt = getJwtCookie()
  const decodedJwt = jwt ? decodeJwt<UserJwtPayload>(jwt) : undefined
  const email = decodedJwt?.email

  return `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_fda2nkbwp429', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"","email":"${email}","externalId":"${getUserSegmentAnonymousId()}","idfa":""});rdt('track', 'PageVisit');`
}
