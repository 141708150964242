import { POWER_HOMEPAGE } from '@lib/routes'

export default function extractOriginalReferrer() {
  const referrer =
    typeof document !== 'undefined'
      ? (document as Document | undefined)?.referrer
      : undefined
  if (!referrer || referrer?.includes(POWER_HOMEPAGE)) {
    // don't pass back an internal referrer, we want to know the original referrer
    return {}
  }
  return { referrer }
}
