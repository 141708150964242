import FormDataStorage from '@lib/forms/FormDataStorage'

const TRIAL_APPLICATION_FORM_NAME = 'trialApplication'

/**
 * Trial Application Form data stored via localStorage
 */
export default class TrialApplicationForm extends FormDataStorage {
  constructor() {
    super(TRIAL_APPLICATION_FORM_NAME)
  }
}
