export const DEFAULT_SENDER_EMAIL = 'hello@withpower.com'
export const SUPPORT_EMAIL = 'hello@withpower.com'
export const FIXED_IP_SENDER_EMAIL = 'hello@m.withpower.com'

export const BASK_BRAND_EMAIL = 'bask.gill@withpower.com'
export const BASK_FIXED_IP_EMAIL = 'bask.gill@m.withpower.com'
export const MESSAGES_FIXED_IP_EMAIL = 'messages@m.withpower.com'
export const BASK_MARKETING_EMAIL = 'bask.gill@e.withpower.com'
export const KAMI_COLD_EMAIL = 'kami@c.withpower.com'
export const LAUREN_COLD_EMAIL = 'lauren@c.withpower.com'
export const LAUREN_DEFAULT_EMAIL = 'lauren@t.withpower.com'
export const CONCIERGE_EMAIL = 'concierge@withpower.com'
