import pino from 'pino'
import { ConsoleLogger } from './ConsoleLogger'
import { LogTags } from './formatLogMessage'

export enum LogSource {
  AWS_EC2_ACTIONS = 'github-actions-aws-ec2',
  FLIGHTCONTROL = 'flightcontrol',
  LOCALHOST = 'localhost',
}

function getHostname(env: NodeJS.ProcessEnv) {
  if (env.GITHUB_SERVER_URL) {
    return env.GITHUB_SERVER_URL
  }

  if (env.FC_URL) {
    return env.FC_URL
  }

  return 'localhost'
}

function getLoggerSource(env: NodeJS.ProcessEnv) {
  if (env.GITHUB_ACTIONS) {
    return LogSource.AWS_EC2_ACTIONS
  } else if (env.FC) {
    return LogSource.FLIGHTCONTROL
  } else {
    return LogSource.LOCALHOST
  }
}

function getDeploymentSha(env: NodeJS.ProcessEnv) {
  if (env.FC_GIT_COMMIT_SHA) {
    return env.FC_GIT_COMMIT_SHA
  }

  return 'unknown'
}

function getBranch(env: NodeJS.ProcessEnv) {
  if (env.FC_GIT_BRANCH) {
    return env.FC_GIT_BRANCH
  }

  return 'unknown'
}

export function buildLoggerDefaults(env = process.env) {
  return {
    base: {
      branch: getBranch(env),
      env:
        env.CURRENT_ENV ?? // ETL sets this to `production`
        env.NODE_ENV,
      hostname: getHostname(env),
      revision: getDeploymentSha(env),
      source: getLoggerSource(env),
      version: getDeploymentSha(env),
    },
    formatters: {
      level: (label: string) => ({ level: label }),
    },
  }
}
export const pinoLoggerDefaults = buildLoggerDefaults(process.env)

export const consoleLogger = pino(pinoLoggerDefaults)

/**
 * Console logger has no dependencies on the server env variables. It can be used on the frontend, or from the context of the serverConfig to avoid circular dependencies
 */
export const createConsoleLogger = (
  logTag?: LogTags | LogTags[] | string[],
) => {
  logTag = [logTag ?? LogTags.UNKNOWN].flat()

  return new ConsoleLogger(logTag)
}
