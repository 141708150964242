import { useEffect, useRef } from 'react'

/**
 * Hook to store the "previous" value of a variable, suggested in the react docs https://reactjs.org/docs/hooks-faq.html
 * React used to support previous props out-of-the-box with componentdidupdate https://reactjs.org/docs/react-component.html#componentdidupdate
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
