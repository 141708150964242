/* eslint-disable sort-keys */
export const typographyStyles = {
  'hero':
    'font-extrabold text-[length:2.5rem] md:text-[length:4rem] leading-[115%] md:leading-[115%]',
  'h1': 'font-extrabold text-[length:2.25rem] md:text-[length:3rem] leading-[115%] md:leading-[115%]',
  'h2': 'font-bold text-[length:2rem] md:text-[length:2.25rem] leading-[115%] md:leading-[115%]',
  'h3': 'font-bold text-[length:1.5rem] md:text-[length:2rem] leading-[115%] md:leading-[115%]',
  'h4': 'font-bold text-[length:1.125rem] md:text-[length:1.5rem] leading-[140%] md:leading-[140%]',
  'h5': 'font-bold text-[length:1rem] md:text-[length:1.125rem] leading-[140%] md:leading-[140%]',
  'h6': 'font-semibold text-[length:0.875rem] md:text-[length:0.875rem] leading-[140%] md:leading-[140%]',
  'p': 'font-medium text-[length:1.125rem] md:text-[length:1.125rem] leading-[180%] md:leading-[180%]',
  'p-strong':
    'font-semibold text-[length:1.125rem] md:text-[length:1.125rem] leading-[180%] md:leading-[180%]',
  'p-small':
    'font-medium text-[length:1rem] md:text-[length:1rem] leading-[180%] md:leading-[180%]',
  'p-small-strong':
    'font-bold text-[length:1rem] md:text-[length:1rem] leading-[180%] md:leading-[180%]',
  'p-tiny':
    'font-medium text-[length:0.875rem] md:text-[length:0.875rem] leading-[140%] md:leading-[140%]',
  'p-tiny-strong':
    'font-bold text-[length:0.875rem] md:text-[length:0.875rem] leading-[140%] md:leading-[140%]',
  'p-xtiny':
    'font-medium text-[length:0.8125rem] md:text-[length:0.8125rem] leading-[140%] md:leading-[140%]',
  'p-xtiny-strong':
    'font-semibold text-[length:0.8125rem] md:text-[length:0.8125rem] leading-[140%] md:leading-[140%]',
  'p-xxtiny':
    'font-medium text-[length:0.75rem] md:text-[length:0.75rem] leading-[140%] md:leading-[140%]',
  'p-xxtiny-strong':
    'font-semibold text-[length:0.75rem] md:text-[length:0.75rem] leading-[140%] md:leading-[140%]',
  'p-3xtiny':
    'font-medium text-[length:0.6875rem] md:text-[length:0.6875rem] leading-[140%] md:leading-[140%]',
  'p-3xtiny-strong':
    'font-semibold text-[length:0.6875rem] md:text-[length:0.6875rem] leading-[140%] md:leading-[140%]',
  'blockquote':
    'font-medium text-[length:1.5rem] md:text-[length:2rem] leading-[140%] md:leading-[140%]',
  'blockquote-small':
    'font-medium text-[length:1.25rem] md:text-[length:1.5rem] leading-[160%] md:leading-[160%]',
  'button':
    'font-extrabold text-[length:1rem] md:text-[length:1.125rem] leading-none',
  'ssp-large':
    'font-medium text-[length:0.875rem] md:text-[length:0.875rem] leading-[160%] md:leading-[160%]',
}
/* eslint-enable sort-keys */

export type TextStyle = keyof typeof typographyStyles

export type ValidTextElement =
  | 'div'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'label'
  | 'li'
  | 'ol'
  | 'p'
  | 'span'
  | 'sup'
  | 'ul'
