/* eslint-disable sort-keys */
/**
 * Colors that we are importing into tailwind. This is manually generated from colors.ts - it would be great to make this 1 source of truth between the tailwind.config.js and our typescript modules
 */
const powerColors = {
  light: '#f2f2f0',
  dark: '#484848',
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  primary50: '#f4f0ff',
  primary100: '#e4daff',
  primary200: '#c9b5ff',
  primary300: '#ad91ff',
  primary400: '#926cff',
  primary500: '#7747ff',
  primary600: '#693af2',
  primary700: '#5e2fe3',
  primary800: '#4a1ec6',
  primary900: '#2d117b',
  grey25: '#fcfcfd',
  grey50: '#f9fafb',
  grey100: '#f2f4f7',
  grey200: '#eaecf0',
  grey300: '#d0d5dd',
  grey400: '#98a2b3',
  grey500: '#667085',
  grey600: '#475467',
  grey700: '#344054',
  grey800: '#182230',
  grey900: '#101828',
  grey950: '#0c111d',
  lightGrey500: '#fefdff',
  lightGrey600: '#fcfbff',
  lightGrey700: '#fbfaff',
  lightGrey800: '#faf8ff',
  lightGrey900: '#f7f4ff',
  mediumGrey500: '#f8f8f8',
  mediumGrey600: '#f3f3f3',
  mediumGrey700: '#ebebeb',
  mediumGrey800: '#dfdfdf',
  mediumGrey900: '#dadada',
  darkGrey500: '#a3a3a3',
  darkGrey600: '#707070',
  darkGrey700: '#525252',
  darkGrey800: '#424242',
  darkGrey900: '#2b2b2b',
  neutral50: '#f9fafb',
  neutral100: '#f3f4f6',
  neutral200: '#e5e7eb',
  neutral300: '#d1d5db',
  neutral400: '#9ca3af',
  neutral500: '#6b7280',
  neutral600: '#4b5563',
  neutral700: '#374151',
  neutral800: '#1f2937',
  neutral900: '#111827',
  warm50: '#fffbf0',
  warm100: '#fff0cc',
  warm200: '#ffe299',
  warm300: '#ffd366',
  warm400: '#ffc533',
  warm500: '#ffb600',
  warm600: '#f2ad00',
  warm700: '#e4a300',
  warm800: '#c88f00',
  warm900: '#a57600',
  hope50: '#fffbfd',
  hope100: '#fff0f8',
  hope200: '#ffe1f0',
  hope300: '#ffd1e9',
  hope400: '#ffc2e1',
  hope500: '#ffb3da',
  hope600: '#faa1cf',
  hope700: '#f283bd',
  hope800: '#e760a6',
  hope900: '#c22376',
  baby50: '#fefbff',
  baby100: '#fdf9fe',
  baby200: '#faf2fd',
  baby300: '#f8ecfb',
  baby400: '#f5e5fa',
  baby500: '#f3dff9',
  baby600: '#eecff8',
  baby700: '#deb2ec',
  baby800: '#cc94de',
  baby900: '#ac63c3',
  blue50: '#f1f7ff',
  blue100: '#d3e6ff',
  blue200: '#a7cdff',
  blue300: '#7bb4ff',
  blue400: '#4f9aff',
  blue500: '#2381ff',
  blue600: '#0d6ceb',
  blue700: '#0059d0',
  blue800: '#004db4',
  blue900: '#00367e',
  turquoise50: '#f5fdff',
  turquoise100: '#e0f7ff',
  turquoise200: '#c0f0ff',
  turquoise300: '#a1e8ff',
  turquoise400: '#81e1ff',
  turquoise500: '#62d9ff',
  turquoise600: '#4ccbf3',
  turquoise700: '#34b4dc',
  turquoise800: '#0e8eb6',
  turquoise900: '#006787',
  seaGreen50: '#edfffe',
  seaGreen100: '#d0fffd',
  seaGreen200: '#b0fffb',
  seaGreen300: '#adfffb',
  seaGreen400: '#a6fffa',
  seaGreen500: '#80fff8',
  seaGreen600: '#5bf3eb',
  seaGreen700: '#11d7cc',
  seaGreen800: '#00a199',
  seaGreen900: '#005652',
  green50: '#f9fdf4',
  green100: '#e7f8d1',
  green200: '#cff2a3',
  green300: '#b7eb76',
  green400: '#a0e548',
  green500: '#88de1a',
  green600: '#68ba00',
  green700: '#508f00',
  green800: '#396500',
  green900: '#254300',
  success50: '#f0fdf4',
  success100: '#dcfce7',
  success200: '#bbf7d0',
  success300: '#86efac',
  success400: '#4ade80',
  success500: '#22c55e',
  success600: '#16a34a',
  success700: '#15803d',
  success800: '#166534',
  success900: '#14532d',
  warning50: '#fffbeb',
  warning100: '#fef3c7',
  warning200: '#fde68a',
  warning300: '#fcd34d',
  warning400: '#fbbf24',
  warning500: '#f59e0b',
  warning600: '#d97706',
  warning700: '#b45309',
  warning800: '#92400e',
  warning900: '#78350f',
  destructive50: '#fef2f2',
  destructive100: '#fee2e2',
  destructive200: '#fecaca',
  destructive300: '#fca5a5',
  destructive400: '#f87171',
  destructive500: '#ef4444',
  destructive600: '#dc2626',
  destructive700: '#b91c1c',
  destructive800: '#7f1d1d',
  destructive900: '#7f1d1d',
}

export default powerColors