export enum LogTags {
  APPLY_PACKAGE_UPDATES = 'apply-package-updates',
  API_ADMIN = 'api-admin',
  AUTH = 'auth',
  AUTO_SMS = 'auto-sms',
  AWS = 'aws',
  BIGQUERY = 'bigquery',
  BING = 'bing',
  CALENDLY = 'calendly',
  CDN_CACHE_WARM_ENQUEUE = 'cdn-cache-warm-enqueue',
  CDN_CACHE_WARM_FETCH = 'cdn-cache-warm-fetch',
  CITE_AS = 'cite-as',
  CENTRAL_SITE = 'central-site',
  CENTRAL_SITE_ETL = 'central-site-etl',
  CONDITION_ETL = 'condition-etl',
  CONDITION_SEARCHES_BY_LOCATION = 'condition-searches-by-location',
  CONDITION_SET_PREFERRED_TERM = 'condition-set-preferred-term',
  CONJURED_ARTICLES = 'conjured-articles',
  CONJURED_ARTICLES_GENERATION = 'conjured-articles-generation',
  CONTACTS = 'contacts',
  CONTACTS_CSV_UPLOAD = 'contacts-csv-upload',
  CONTACT_ENRICHMENT = 'contact-enrichment',
  CONTACT_ROUNDUPS = 'contact-roundups',
  CRC_COPILOT = 'crc-copilot',
  CRIO_WEBHOOK = 'crio-webhook',
  CTMS = 'ctms',
  CUSTOM_CONSENT_FORM = 'custom-consent-form',
  DAILY_CO = 'daily-co',
  DATAVANT = 'datavant',
  DRUGBANK_ETL = 'drugbank-etl',
  DRUG_NLP = 'drug-nlp',
  DOCTOR_ENTITIES = 'doctor-entities',
  ELIGIBILITY_CRITERIA = 'eligibility-criteria',
  ELIGIBILITY_CRITERIA_CONCEPT_SEARCH = 'eligibility-criteria-concept-search',
  EMAIL_PATIENT_TRIAL_APPLY_NEXT_STEPS = 'email-patient-trial-apply-next-steps',
  EMAIL_RELAY = 'email-relay',
  EMR_SYNOPSIS = 'emr-synopsis',
  EXPERIMENT_SERVICE = 'experiment-service',
  EXTERNAL_FETCH = 'external-fetch',
  FACILITY = 'facility',
  FAX = 'fax',
  FACEBOOK_MARKETING_API = 'facebook-marketing-api',
  FEASIBILITY_ESTIMATE = 'feasibility-estimate',
  FHIR = 'fhir',
  FREE_PREMIUM_TRIAL = 'free-premium-trial',
  GEOCODER_ADDRESS = 'geocoder-address',
  GEOCODER_IP = 'geocoder-ip',
  GLOBAL_DATA = 'global-data-client',
  GRAPHITE_API = 'graphite-api',
  HEALTH_CHECK = 'health-check',
  HOSPITAL = 'hospital',
  IMAGE_SERVICE = 'image-service',
  INBOX = 'inbox',
  INFRA_SERVICE = 'infra-service',
  INTEGRATIONS = 'integrations',
  INTERNAL_FETCH = 'internal-fetch',
  INTERVENTION_ETL = 'intervention-etl',
  LIGHTHOUSE = 'lighthouse',
  LIVEKIT = 'livekit',
  MEMR_API = 'memr-api',
  MEMR_FACESHEETS = 'memr-facesheets',
  MESH_API = 'mesh-api',
  MESSAGES = 'messages',
  MIDDLEWARE = 'middleware',
  MIDJOURNEY_API = 'midjourney-api',
  NLP_SCORES = 'nlp-scores',
  NPI_API = 'npi-api',
  ONBOARDING_NOTIFICATION = 'onboarding-notification',
  OPENAI = 'openai',
  OPEN_TELEMETRY = 'open-telemetry',
  OUTBOUND_EMAIL_SEND = 'outbound-email-send',
  OUTCOME_ETL = 'outcome-etl',
  PAGE_VIEWS = 'page-views',
  PARTICLE_API = 'particle-api',
  PATIENT = 'patient',
  PATIENT_API = 'patient-api',
  PATIENT_DOSSIER = 'patient-dossier',
  PATIENT_DOSSIER_NOTIFICATIONS = 'patient-dossier-notifications',
  PATIENT_EMAILS = 'patient-emails',
  PATIENT_NAVIGATOR_AGENT = 'patient-navigator-agent',
  PATIENT_POST_TRIAL_APPLY = 'patient-post-trial-apply',
  PATIENT_PROFILE = 'patient-profile',
  PATIENT_SCHEDULED_APPOINTMENT = 'patient-scheduled-appointment',
  PATIENT_TRIAL_APPLICATION = 'patient-trial-application',
  PATIENT_VIDEO_CALL = 'patient-video-call',
  PDF_GENERATION = 'pdf-generation',
  PDF_VIEWER = 'pdf-viewer',
  PHI_LEAKAGE = 'phi-leakage',
  PHONE_RELAY = 'phone-relay',
  PLAYHT_WEBHOOK = 'playht-webhook',
  PRINCIPAL_INVESTIGATOR = 'principal-investigator',
  PRISMA_ERROR = 'prisma-error',
  PRISMA_QUERY = 'prisma-query',
  PRODUCT_TOURS = 'product-tours',
  PUBMED_API = 'pubmed-api',
  PUSHER_BEAMS = 'pusher-beams',
  QUORA = 'quora',
  QUEUE = 'queue',
  REACT_BRICKS = 'react-bricks',
  REDIS = 'redis',
  REFERRAL_CODE = 'referral-code',
  REGISTRY = 'registry',
  REGISTRY_ROUNDUP_EMAIL = 'registry-roundup-email',
  RELAY = 'relay',
  RELEASE_OLD_TWILIO_NUMBERS = 'release-old-twilio-numbers',
  REPLICATE = 'replicate-ai',
  RESEARCH_DOCUMENT = 'research-document',
  RETELL_AI = 'retell-ai',
  RETRYABLE_FETCH = 'retryable-fetch',
  SCRAPER_CLIENT = 'scraper-client',
  SCREENING_VISIT_OUTCOME_CONFIRMATION = 'screening-visit-outcome-confirmation',
  SCREENING_VISIT_REMINDERS = 'screening-visit-reminders',
  SITEMAP = 'sitemap',
  SEGMENT = 'segment',
  SENDBIRD = 'sendbird',
  SERP_API = 'serp-api',
  SHORT_CODE = 'short-code',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  SIGNWELL_WEBHOOK = 'signwell-webhook',
  SITE_MONTHLY_BRIEF_EMAIL = 'site-monthly-brief-email',
  SLACK = 'slack',
  SMS_RELAY = 'sms-relay',
  SPONSOR_PAGE = 'sponsor-page',
  SPONSOR_MONTHLY_BRIEF_EMAIL = 'sponsor-monthly-brief-email',
  SSP_PRESCREENER = 'ssp-prescreener',
  TEST = 'test',
  THERAPEUTIC_AREAS = 'therapeutic-areas',
  TOPIC_ETL = 'topic-etl',
  TOPIC_INDEX_NOW = 'topic-index-now',
  TOPIC_PAGE = 'topic-page',
  TRANSMOGRIFY_ARTICLE_GENERATION = 'transmogrify-article-generation',
  TREATMENT_ETL = 'treatment-etl',
  TREATMENT_PAGE = 'treatment-page',
  TREATMENT_TOPIC_IMAGE = 'treatment-topic-image',
  TRIAL_ADMIN = 'trial-admin',
  TRIAL_APPLICATION_QUALIFIED_REFERRAL_EMAIL = 'trial-application-qualified-referral-email',
  TRIAL_APPLY_ELIGIBILITY_QUALIFICATION = 'trial-apply-eligibility-qualification',
  TRIAL_APPLY_ELIGIBILITY_RESPONSE = 'trial-apply-eligibility-response',
  TRIAL_APPLY_NOTE = 'trial-apply-note',
  TRIAL_APPLY_RANKING = 'trial-apply-ranking',
  TRIAL_CONSENT_FORM = 'trial-consent-form',
  TRIAL_EDIT_NOTIFICATION = 'trial-edit-notification',
  TRIAL_ETL = 'trial-etl',
  TRIAL_FINDER_PARTNERS = 'trial-finder-partners',
  TRIAL_FOLLOW_UP_REMINDER = 'trial-follow-up-reminder',
  TRIAL_HELPER = 'trial-helper',
  TRIAL_INDEX_NOW = 'trial-index-now',
  TRIAL_LOCATIONS = 'trial-locations',
  TRIAL_NLP = 'trial-nlp',
  TRIAL_PAGE = 'trial-page',
  TRIAL_PERFORMANCE_ROUNDUP = 'trial-performance-roundup',
  TRIAL_PRO = 'trial-pro',
  TRIAL_PRO_TASKS = 'trial-pro-tasks',
  TRIAL_PRO_ETL = 'trial-pro-etl',
  TRIAL_PRO_INVITE = 'trial-pro-invite',
  TRIAL_PRO_SECURED_KEY = 'trial-pro-secured-key',
  TRIAL_RECOMMENDATIONS = 'trial-recommendations',
  TRIAL_VIDEO_GENERATION = 'trial-video-generation',
  TRIAL_VIDEOS = 'trial-videos',
  TWILIO = 'twilio',
  TYPEFORM_WEBHOOK = 'typeform-webhook',
  UMLS_API = 'umls-api',
  UNKNOWN = 'unknown',
  USAGE_EVENT = 'usage-event',
  USER = 'user',
  USER_ACTIVITY = 'user-activity',
  USER_ONBOARDING_EMAILS = 'user-onboarding-emails',
  USER_SEARCH = 'user-search',
  USER_TRIAL_NOTIFICATIONS = 'user-trial-notifications',
  USER_TRIAL_RECOMMENDATIONS = 'user-trial-recommendations',
  UTILS = 'utils',
  X_CURES_API = 'x-cures-api',
  ZAPIER = 'zapier',
  ZERO_BOUNCE = 'zero-bounce',
  ZIPCODE_ETL = 'zipcode-etl',
}

export enum DataTagLabel {
  AUTHOR_TYPE = 'author-type',
  AUTHOR_USER_ID = 'author-user-id',
  CALL_SID = 'call-sid',
  MEMR_QUERY_ID_TOKEN = 'memr-query-id-token',
  PATIENT_ID = 'patient-id',
  PATIENT_NAVIGATOR_AGENT_CALL_ID = 'patient-navigator-agent-call-id',
  PATIENT_TRIAL_APPLICATION = 'patient-trial-application',
  PATIENT_TRIAL_APPLICATION_ID = 'patient-trial-application-id',
  PATIENT_TRIAL_APPLICATION_ID_TOKEN = 'patient-trial-application-id-token',
  RELAY_PHONE = 'relay-phone',
  RELAY_THREAD = 'relay-thread',
  SOURCE_APPLY_ID_TOKEN = 'source-apply-id-token',
  TRIAL_EDIT_ID = 'trial-edit-id',
  TRIAL_LOCATION_ID = 'trial-location-id',
  TRIAL_NCT_ID = 'trial-nct-id',
  USAGE_EVENT_ID = 'usage-event-id',
  USER_ID = 'user-id',
}

export const dataTag = (label: DataTagLabel, datum: string | number) =>
  [label, datum].join(':')

export const formatLogMessage = (
  logTags: LogTags | LogTags[] | string[] = [LogTags.UNKNOWN],
  message: string,
) => {
  logTags = [logTags].flat() // guarantee a single dimensional array
  const prefix = logTags.flatMap((logTag) => `[${logTag}]`) // surround each logtag in square brackets
  return [...prefix, message].join(' ')
}
