import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { MouseEvent } from 'react'
import { FontAwesomeSvgIcon } from './FontAwesomeSvgIcon'

export type SizeProp =
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
  | '2xs'
  | 'xs'
  | 'sm'
  | 'lg'
  | 'xl'
  | '2xl'

/**
 * @param icon - icon from fontawesome such as iconPack.faAlarmPlus
 * @returns An icon SVG tag
 */
export const Icon = ({
  className,
  icon,
  onClick,
  size = '1x',
}: {
  className?: string
  icon: IconDefinition
  onClick?: (event: MouseEvent) => void
  size?: SizeProp
}) => {
  return (
    <FontAwesomeSvgIcon
      className={className}
      icon={icon}
      onClick={onClick}
      size={size}
    />
  )
}
