import Head from 'next/head'
import Script from 'next/script'

export default function generateGA4AnalyticsScripts() {
  return (
    <>
      <Head>
        <link href='https://www.googletagmanager.com' rel='dns-prefetch' />
      </Head>
      <Script src='https://www.googletagmanager.com/gtag/js?id=G-M4XY5P39E9' />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-M4XY5P39E9');
          `,
        }}
        id='ga4-init'
        strategy='afterInteractive'
      />
    </>
  )
}
