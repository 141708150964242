import type { DetailedHTMLProps, ScriptHTMLAttributes } from 'react'
import type { BreadcrumbsProps } from './Breadcrumbs'
import Breadcrumbs from './Breadcrumbs'

export default function StructuredData({
  id,
  scriptTagProps,
}: {
  id: string
  scriptTagProps: DetailedHTMLProps<
    ScriptHTMLAttributes<HTMLScriptElement>,
    HTMLScriptElement
  >
}) {
  return <script id={id} type='application/ld+json' {...scriptTagProps} />
}

const StructuredDataBreadcrumbs = function (props: BreadcrumbsProps) {
  return <Breadcrumbs {...props} />
}

StructuredData.Breadcrumbs = StructuredDataBreadcrumbs
