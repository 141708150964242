import { RequestMethod, type ApiResponseDataSuccess } from '@lib/api/consts'
import { createConsoleLogger } from '@lib/logger'
import { LogTags } from '@lib/logger/formatLogMessage'
import absoluteUrl from '@lib/routes/helpers/absoluteUrl'
import type { StringDictionary } from '@lib/types/dictionary'
import unknownToString from '@lib/types/unknownToString'
import fetch from 'cross-fetch' // If headers is not imported from cross-fetch, the code throws an error
import type FormData from 'form-data'
import attachBearerToken from './attachBearerToken'
import { isExternalUrl } from './isExternalUrl'

const logger = createConsoleLogger(LogTags.INTERNAL_FETCH)

const validRequestMethods = [
  RequestMethod.DELETE,
  RequestMethod.GET,
  RequestMethod.POST,
  RequestMethod.PUT,
]

export type InternalJsonFetchRequest = {
  authenticated: boolean
  body?:
    | string
    | Record<
        string | number,
        object | string | string[] | Blob | number | undefined | boolean | null
      >
    | FormData
  headers?: Headers | Record<string, unknown>
  params?: StringDictionary
  requestMethod: RequestMethod
  timeoutMs?: number
  url: string
}

export type InternalJsonFetchResponse<T> =
  | {
      statusCode?: number
      success: false
    }
  | {
      data?: T
      success: true
    }

/**
 * A wrapper around the native `fetch` to use for requests against any
 * internal APIs that utilize JSON as the request and response payload formats.
 */
export default async function internalJsonFetch<T = unknown>(
  req: InternalJsonFetchRequest,
): Promise<InternalJsonFetchResponse<T>> {
  if (isExternalUrl(req.url)) {
    throw new Error(
      `internalJsonFetch to ${req.url} must be used for internal APIs only; use externalFetch for external APIs`,
    )
  }

  if (req.url.startsWith('/')) {
    req.url = absoluteUrl(req.url)
  }

  try {
    if (!validRequestMethods.includes(req.requestMethod)) {
      logger.error(`Invalid request method ${req.requestMethod}`)
      return {
        success: false,
      }
    }

    if (req.authenticated) {
      req = attachBearerToken(req)
    }

    const response = await (async function handleFetch() {
      const params = {
        body: JSON.stringify(req.body),
        headers: {
          ...req.headers,
          'Content-Type': 'application/json',
        },
        method: req.requestMethod,
        signal: req.timeoutMs ? AbortSignal.timeout(req.timeoutMs) : undefined,
      }

      const url =
        req.requestMethod === RequestMethod.GET && req.params
          ? `${req.url}?${new URLSearchParams(req.params).toString()}`
          : req.url

      return fetch(url, params)
    })()

    return await formatResponse<T>(response)
  } catch (error: unknown) {
    logger.error(`Error hitting ${req.url} ${error}`)
    return {
      success: false,
    }
  }
}

async function formatResponse<T>(
  response: Response,
): Promise<InternalJsonFetchResponse<T>> {
  if (!response.ok) {
    const maybeClonedResponseBody = await getErrorResponseBody(response)
    logger.error(
      `Error hitting ${response.url}, received a ${
        response.status
      }, ${JSON.stringify(response.statusText)}, response ${JSON.stringify(
        maybeClonedResponseBody,
      )}`,
    )
    return {
      statusCode: response.status,
      success: false,
    }
  }

  const parsedResponse = (await response.json()) as ApiResponseDataSuccess<T>

  if (parsedResponse.status === 'error') {
    return {
      success: false,
    }
  }

  return {
    data: parsedResponse.data,
    success: true,
  }
}

async function getErrorResponseBody(response: Response) {
  try {
    const maybeErrorResponseBody = await response.clone().text()
    return maybeErrorResponseBody
  } catch (e: unknown) {
    return unknownToString(e)
  }
}
