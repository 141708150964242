import { type UTMParameters } from './types'

export const getUtmParamsFromSearchParams = (
  searchParams: URLSearchParams | null,
): UTMParameters => ({
  utm_campaign: searchParams?.get('utm_campaign') ?? undefined,
  utm_content: searchParams?.get('utm_content') ?? undefined,
  utm_medium: searchParams?.get('utm_medium') ?? undefined,
  utm_source: searchParams?.get('utm_source') ?? undefined,
  utm_term: searchParams?.get('utm_term') ?? undefined,
})
