import Head from 'next/head'

export default function NoIndex() {
  return (
    <Head>
      <meta content='noindex,noimageindex,follow' key='robots' name='robots' />
      <meta
        content='noindex,noimageindex,follow'
        key='googlebot'
        name='googlebot'
      />
    </Head>
  )
}
