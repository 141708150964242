import type { Debugger } from 'debug'
import debug from 'debug'
import pino from 'pino'

// Configure a pino "debug" level logger
const pinoDebugLogger = pino({
  base: {
    env: process.env.NODE_ENV,
    revision: process.env.VERCEL_GITHUB_COMMIT_SHA,
  },
  formatters: {
    level(level) {
      return { level }
    },
  },
  level: 'debug',
  levelVal: 20,
})

// Hold all instances of debug logs
const debugLogMap = new Map<string, Debugger>()

// Configure the main entrypoint as `app:withpower:*` and attach pino as output
const appDebugLogger = debug('app')
appDebugLogger.log = (message) => pinoDebugLogger.debug(message)
const withPowerDebugLogger = appDebugLogger.extend('withpower')
debugLogMap.set('app', appDebugLogger)
debugLogMap.set('app:withpower', withPowerDebugLogger)

/**
 * Write out to a pino-enabled DEBUG log based on a namespace prefixed with `app:withpower`
 */
export default function debugLogger(namespace: string, message: string) {
  let logInstance = debugLogMap.get(namespace)
  if (!logInstance) {
    logInstance = withPowerDebugLogger.extend(namespace)
    debugLogMap.set(`app:withpower:${namespace}`, logInstance)
  }

  logInstance(message)
}
