/**
 * Converts an unknown value to a string;
 * useful for logging exceptions when the exception type is not known
 * https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */
export default function unknownToString(u: unknown): string {
  // JSON.stringify can throw an exception if it detects a cyclical object
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#exceptions
  try {
    if (u instanceof Error) {
      return u.message
    }

    if (typeof u === 'string') {
      return u
    }

    return JSON.stringify(u, null, 2)
  } catch (e) {
    return ''
  }
}
