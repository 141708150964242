import { getLatLngFromWindowUrl } from '@components/hooks/GeolocationProvider/getLatLngFromWindowUrl'
import type { UrlParamCookie } from '@lib/browsers/cookies/extractUtmParamsFromCookie'
import extractUtmParamsFromCookie from '@lib/browsers/cookies/extractUtmParamsFromCookie'

function getQueryParamsToAppendToLink(urlParams?: UrlParamCookie) {
  const { queryLatitude, queryLongitude } = getLatLngFromWindowUrl()
  if (!queryLatitude || !queryLongitude) {
    return extractUtmParamsFromCookie(urlParams)
  }
  return {
    ...extractUtmParamsFromCookie(urlParams),
    lat: queryLatitude,
    lng: queryLongitude,
  }
}

export default getQueryParamsToAppendToLink
