'use client'

import type { GeolocationWithLocationName } from '@lib/types/Geolocation'
import type { ReactElement, ReactNode } from 'react'
import { createContext, useCallback, useEffect, useState } from 'react'
import getLatLngFromIp from './getLatLngFromIp'
import { getLatLngFromWindowUrl } from './getLatLngFromWindowUrl'

/**
 * Provide a GeolocationContext to be able to share lat / lng and locationName from child components.
 * Import this in a `useContext`: https://reactjs.org/docs/hooks-reference.html#usecontext
 */
export const GeolocationContext = createContext<GeolocationWithLocationName>({
  latitude: null,
  locationName: undefined,
  longitude: null,
})

type GeolocationProviderProps = {
  children: ReactElement | ReactElement[] | ReactNode
}
function GeolocationProvider({ children }: GeolocationProviderProps) {
  const searchParams =
    typeof window !== 'undefined' ? window.location.search : ''

  const [coords, setCoords] = useState<GeolocationWithLocationName>({
    latitude: null,
    locationName: undefined,
    longitude: null,
  })

  const determineUserLocation = useCallback(async () => {
    // If we have it in the query params, thats the source of truth, some input form has modified it
    const { queryLatitude, queryLocationName, queryLongitude } =
      getLatLngFromWindowUrl()
    if (queryLatitude && queryLongitude) {
      return setCoords({
        latitude: parseFloat(queryLatitude),
        locationName: queryLocationName ?? undefined,
        longitude: parseFloat(queryLongitude),
      })
    }
    // Otherwise, grab the userLocation from the IP and stuff it into our context state
    const urlParams = new URLSearchParams(window.location.search)
    const queryIp = urlParams.get('ip')
    const ip = queryIp ? queryIp : undefined
    const { latitude, locationName, longitude } = await getLatLngFromIp(ip)
    if (latitude && longitude) {
      return setCoords({ latitude, locationName, longitude })
    }
  }, [setCoords, coords.latitude, coords.longitude])

  /**
   * This effect is triggered if the url is changed (like when a zipcode input form triggers a query param change)
   */
  useEffect(() => {
    determineUserLocation()
  }, [searchParams, determineUserLocation])

  return (
    <GeolocationContext.Provider value={coords}>
      {children}
    </GeolocationContext.Provider>
  )
}

export default GeolocationProvider
