import GeolocationProvider from '@components/hooks/GeolocationProvider/index.client'
import type { ReactElement } from 'react'
import { CookiesProvider } from 'react-cookie'
import {
  PageLayoutAndTracking,
  type LayoutProps,
} from './HeaderComponents/PageLayoutAndTracking'

/**
 * Wrapper for `Layout` that includes providers for urlParams, geolocation, cookies but no trial application context
 *
 * @param LayoutProps LayoutProps
 */
export function LayoutWithCookiesAndGeolocation(props: LayoutProps) {
  return (
    <CookiesProvider>
      <GeolocationProvider>
        <PageLayoutAndTracking {...props} />
      </GeolocationProvider>
    </CookiesProvider>
  )
}

export function getLayoutWithCookiesAndGeolocation(props: LayoutProps) {
  return function Component(page: ReactElement) {
    return (
      <LayoutWithCookiesAndGeolocation {...props}>
        {page}
      </LayoutWithCookiesAndGeolocation>
    )
  }
}

export function getMinimalLayoutWithTracking(props: LayoutProps) {
  return function Component(page: ReactElement) {
    return <PageLayoutAndTracking {...props}>{page}</PageLayoutAndTracking>
  }
}
