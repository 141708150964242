import type { SegmentUTMContext, UTMParameters } from '../browsers/utm/types'

export const convertToSegmentUTMs = (
  utmParams: UTMParameters,
): SegmentUTMContext => {
  return {
    content: utmParams.utm_content,
    medium: utmParams.utm_medium,
    name: utmParams.utm_campaign,
    source: utmParams.utm_source,
    term: utmParams.utm_term,
  }
}
