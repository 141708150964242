export const isProcessClientSide = () => {
  if (typeof process === 'undefined') {
    return true
  } else {
    return process.browser
  }
}

export const isJestEnv = () => {
  return (
    process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test'
  )
}
