// eslint-disable-next-line no-restricted-imports
import { extendTailwindMerge } from 'tailwind-merge'
// eslint-disable-next-line no-restricted-imports
import clsx from 'clsx'
import tailwindConfig from '../../tailwind.config'

function isValidZIndexClass(className: string): boolean {
  const [_z, key] = className.split('-')
  return key! in tailwindConfig.theme.extend.zIndex
}

/**
 * @see https://github.com/dcastil/tailwind-merge/blob/v2.3.0/docs/configuration.md
 */
const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      z: ['auto', '0', '1', '10', '20', '30', '40', '50', isValidZIndexClass],
    },
  },
})

// Utility to intelligently merge tailwind classnames. See test file for examples
export function classMerge(...args: clsx.ClassValue[]): string {
  return customTwMerge(clsx(...args))
}
