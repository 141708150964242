import Head from 'next/head'

interface FaviconProps {
  inSSP: boolean
}

export function getFaviconPath({
  env = process.env.CURRENT_ENV ?? process.env.NEXT_PUBLIC_CURRENT_ENV,
  inSSP,
  withNotifications = false,
}: {
  env?: string
  inSSP: boolean
  withNotifications?: boolean
}) {
  switch (env) {
    case 'production':
    case 'preview':
    case 'development':
    default:
      if (inSSP) {
        return withNotifications
          ? '/supply-favicon-notif-320.png'
          : '/supply-favicon-320.png'
      } else {
        return withNotifications
          ? '/patient-favicon-notif-320.png'
          : '/patient-favicon-320.png'
      }
    // case 'preview':
    //   return '/preview_favicon.png'
    // case 'development':
    //   return '/development_favicon.png'
    // default:
    //   return '/patient-favicon-320.png'
  }
}

export default function Favicon({ inSSP }: FaviconProps) {
  const faviconPath = getFaviconPath({ inSSP })
  return (
    <Head>
      <link href={faviconPath} rel='icon' />
    </Head>
  )
}
