import { Icon, type SizeProp } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { ReactElement } from 'react'
import Button, { type ButtonProps } from './index'

export type IconButtonProps = Omit<ButtonProps, 'size' | 'value'> & {
  children?: ReactElement
  childrenClassName?: string
  containerClassName?: string
  icon: IconDefinition
  iconClassName?: string
  iconPosition?: 'left' | 'right'
  iconSize?: SizeProp
}

export const IconButton = (props: IconButtonProps) => {
  const {
    children,
    childrenClassName,
    className,
    containerClassName,
    ...rest
  } = props
  return (
    <div className={classMerge('inline-flex', containerClassName)}>
      <Button
        className={classMerge(
          'flex',
          'items-center',
          'justify-center',
          '!p-4',
          'w-full',
          className,
        )}
        {...rest}
      >
        <div
          className={classMerge('flex items-center', childrenClassName, {
            'flex-row-reverse': props.iconPosition === 'right',
          })}
        >
          <Icon
            className={props.iconClassName}
            icon={props.icon}
            size={props.iconSize}
          />
          {children}
        </div>
      </Button>
    </div>
  )
}
