import Head from 'next/head'

/**
 * Appends "| Power" to the page title, also setting the default title if none is provided
 */
export default function TitleForHead({ title }: { title?: string }) {
  const defaultTitle =
    'Explore Novel Therapies & Enroll In Clinical Trials | Power'
  const realTitle = title ? `${title} | Power` : defaultTitle

  return (
    <Head>
      <title>{realTitle}</title>
    </Head>
  )
}
