import type { ReadonlyURLSearchParams } from 'next/navigation'

export const getPageTrackPropsFromRouter = (
  pathname: string,
  searchParams: URLSearchParams | ReadonlyURLSearchParams | null,
) => {
  const urlParams = new URLSearchParams(window?.location?.search)
  const urlParamsObject = Object.fromEntries(urlParams.entries())
  const searchParamObject = Object.fromEntries(searchParams?.entries() ?? [])

  return {
    path: window?.location?.pathname,
    queryParams: { ...urlParamsObject, ...searchParamObject },
    route: pathname,
  }
}
