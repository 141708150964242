import { classMerge } from '@components/utilities/classMerge'
import { createElement, type ForwardedRef, type ReactNode } from 'react'
import {
  typographyStyles,
  type TextStyle,
  type ValidTextElement,
} from './fontStyles'

type CustomTextProps = {
  element?: ValidTextElement
  forwardedRef?: ForwardedRef<Element>
  hideTitle?: boolean
  styleName?: TextStyle
  value?: string | ReactNode
}

export type TextProps = React.HTMLAttributes<HTMLElement> & CustomTextProps
export const textStyles = Object.keys(typographyStyles) as TextStyle[]

export const TEXT_DEFAULT_COLOR = 'text-black'
export const TEXT_DEFAULT_STYLE_NAME = 'p'

/**
 * Generic Text wrapper to minimize requisite style choices.
 */
export const Text = ({
  children,
  element = 'span',
  forwardedRef,
  hideTitle = false,
  styleName = TEXT_DEFAULT_STYLE_NAME,
  value,
  ...rest
}: TextProps) => {
  let {
    className,
    id,
    onClick,
    style,
    suppressHydrationWarning = false,
    title,
  } = rest

  // Provide a title attribute for hover effects if there's line clamp and the value is a string
  if (
    className?.includes('line-clamp') &&
    !Boolean(hideTitle) &&
    (typeof title === 'undefined' || title === null) &&
    typeof value === 'string'
  ) {
    title = value
  }
  const mergedClasses = classMerge(
    TEXT_DEFAULT_COLOR,
    'break-words',
    'block', // this was our original default. IMO we should have them be inline as default, but here we are
    'py-[1px]', // I don't think this should be there, but it has been included for a while and removing it would cause significant ui drift.
    typographyStyles[styleName],
    className, // className needs to be after typography so that we can override the base typography styles
    onClick ? 'cursor-pointer' : '',
  )

  return createElement(
    element,
    {
      ...rest,
      className: mergedClasses,
      id,
      onClick,
      ref: forwardedRef,
      style: style,
      suppressHydrationWarning,
      title,
    },
    value,
    children,
  )
}

Text.Error = function ErrorText({ styleName = 'p-small', ...rest }: TextProps) {
  return (
    <Text
      {...rest}
      className={classMerge(rest.className, 'text-destructive500')}
      styleName={styleName}
    />
  )
}
