import type { Url } from '@lib/routes'
import { jsonLdScriptProps } from 'react-schemaorg'
import type { BreadcrumbList, ListItem } from 'schema-dts'
import StructuredData from '.'

export type BreadcrumbItem = {
  link: Url
  name: string
}

export interface BreadcrumbsProps {
  items: BreadcrumbItem[]
  name: string
}

function buildBreadcrumbListItem(
  item: BreadcrumbItem,
  index: number,
): ListItem {
  return {
    '@type': 'ListItem',
    'item': item.link.toString(),
    'name': item.name,
    'position': index,
  }
}

export default function Breadcrumbs({ items, name }: BreadcrumbsProps) {
  return (
    <StructuredData
      id={`page-breadcrumbs-structured-data`}
      scriptTagProps={jsonLdScriptProps<BreadcrumbList>({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items.map(buildBreadcrumbListItem),
        'name': name,
      })}
    />
  )
}
