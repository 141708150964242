import MetricsAndTrackingScripts from './MetricsAndTrackingScripts'
import { UtmCookiesAndNavigationEvents } from './UtmCookiesAndNavigationEvents.client'
import type { MetricsAndTrackingProps } from './types'

export default function MetricsAndTracking({
  loadHotJar,
  loadMaze = false,
  loadRedditPixel = true,
}: MetricsAndTrackingProps) {
  return (
    <>
      <MetricsAndTrackingScripts
        loadHotJar={loadHotJar}
        loadMaze={loadMaze}
        loadRedditPixel={loadRedditPixel}
      />
      <UtmCookiesAndNavigationEvents />
    </>
  )
}
