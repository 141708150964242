import UserInactivityModal from '@components/app/UserInactivityModal'
import { ONE_MINUTE_IN_MS } from '@lib/utilities/time'
import type { ReactElement } from 'react'
import { createContext, useEffect, useState } from 'react'
import useIsInactive from '../useIsInactive'
import useIsSignedIn from '../useIsSignedIn'

const USER_INACTIVITY_TIMEOUT_IN_MS = 179 * ONE_MINUTE_IN_MS // three hours including extra minute from modal timeout

/**
 * Provide an AuthenticationContext to wrap the entire application with required functionality for security or compliance purposes.
 * Import this in a `useContext`: https://reactjs.org/docs/hooks-reference.html#usecontext
 */
export const AuthenticationContext = createContext<undefined>(undefined)

type AuthenticationProviderProps = {
  children: ReactElement | ReactElement[]
}

function AuthenticationProvider({ children }: AuthenticationProviderProps) {
  const [isInactive, resetTimer] = useIsInactive(USER_INACTIVITY_TIMEOUT_IN_MS)
  const isSignedIn = useIsSignedIn()

  const [inactivityModalOpen, setInactivityModalOpen] = useState(false)

  useEffect(() => {
    if (!isSignedIn || !isInactive) {
      return
    }

    setInactivityModalOpen(true)
  }, [isInactive])

  function handleInactivityModalClose() {
    setInactivityModalOpen(false)
    resetTimer()
  }

  return (
    <AuthenticationContext.Provider value={undefined}>
      <>
        <UserInactivityModal
          onClose={handleInactivityModalClose}
          visible={inactivityModalOpen}
        />
        {children}
      </>
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
